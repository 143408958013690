import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BarChartIcon from '@mui/icons-material/BarChart';
import React, { useEffect, useState } from 'react';

import DashboardNavbarRoot, {
  DashboardIconRoot,
  DashboardMenuRoot,
  InstructionBox,
  InstructionHeader,
  InstructionPopup,
} from './DashboardNavbar.styled';

import useAppStates from 'store/slices/states/useAppStates';

import theme from 'theme/theme';
import { Logo } from 'layout';
import { BaseModal } from 'UI';
import DICTIONARY from 'constants/Dictionary';

const DashboardNavbar = () => {
  const { handleStatisticOpen } = useAppStates();
  const [openInstruction, setOpenInstruction] = useState(false);

  const handleOpen = () => {
    handleStatisticOpen(true);
  };

  const handleOpenInstruction = () => {
    setOpenInstruction((prevState) => !prevState);
  };

  const renderPopup = (
    <BaseModal
      open={openInstruction}
      onClose={handleOpenInstruction}
      sx={{
        width: '600px',
        top: '50%',
        '@media (max-width: 850px)': {
          width: '100%',
          maxWidth: '100%',
          padding: '10px 20px',
        },
      }}
    >
      <InstructionPopup>
        <InstructionBox style={{ width: '100%' }}>
          <p>
            <b>Instructions</b>
          </p>
        </InstructionBox>
        <InstructionBox>
          <p>
            <b>Game rules</b>
          </p>
          <p>
            Select a player for each cell that matches the criteria for the
            intersection of that cell's row and column. You cannot guess the
            same correct player for more than one square. Players may be active
            or inactive but must have previously played at least one Indian
            Premier League. For awards and statistics, the player does not have
            to have earned the accomplishment while on that team. For career
            stats they can be accumulated across multiple seasons across
            multiple teams. Players have 10 guesses to fill out the grid. Aim
            for a low rarity.
          </p>
        </InstructionBox>
        <InstructionBox>
          <p>
            <b>Rarity Score</b>
          </p>
          <p style={{ margin: 0 }}>
            Your rarity score rewards you for how unique your answers are.
          </p>
          <p style={{ margin: 0 }}>
            Every correct answer is worth up to 100 points. The more rare your
            answers are compared to other players, the higher your score will
            be.
          </p>
          <p style={{ margin: 0 }}>
            If you guess a player that only 10% of other players have guessed,
            you will score 100 – 10% = 90 for that square.
          </p>
          <p style={{ margin: 0 }}>
            Your total score at the end of the game is the sum of all 9 squares.
          </p>
          <p style={{ margin: 0 }}>Any blank squares score 0.</p>
          <p>The closest you can get to 900, the better! Good luck.</p>
        </InstructionBox>

        <InstructionBox style={{ width: '100%' }}>
          <p>
            <b>निर्देश</b>
          </p>
        </InstructionBox>
        <InstructionBox>
          <p>
            <b>खेल के नियम</b>
          </p>
          <p style={{ margin: 0 }}>
            प्रत्येक सेल के ललए एक खिलाड़ी का चयन करें जो उस सेल क़ी पंखि और
            स्तंभ के प्रलिच्छेदन के मानदंड से मेल िािा हो।
          </p>
          <p style={{ margin: 0 }}>
            आप एक से अलिक वर्ग के ललए एक ह़ी सह़ी खिलाड़ी का अनुमान नह़ीं लर्ा
            सकिे।
          </p>
          <p style={{ margin: 0 }}>
            खिलाड़ी सलिय या लनखिय हो सकिे हैं लेलकन उन्ोंने पहले कम से कम एक
            इंलडयन प्ऱीलमयर ल़ीर् िेला हो।
          </p>
          <p style={{ margin: 0 }}>
            पुरस्कारों और आँकडों के ललए, खिलाड़ी को उस ट़ीम में रहिे हुए उपलखि
            अलजगि करना आवश्यक नह़ीं है। कै ररयर आँकडों के ललए उन्ें कई ट़ीमों
            में कई स़ीज़न में जमा लकया जा सकिा है।
          </p>
          <p style={{ margin: 0 }}>
            खिलालडयों के पास लिड को भरने के ललए 10 अनुमान हैं। कम दुलगभिा का
            लक्ष्य रिें.
          </p>
        </InstructionBox>

        <InstructionBox>
          <p>
            <b>र्दुललभता स्कोर</b>
          </p>
          <p style={{ margin: 0 }}>
            आपके उत्तर लकिने अनूठे हैं, इसके ललए आपका दुलगभिा स्कोर आपको पुरस्कृ
            ि करिा है।
          </p>
          <p style={{ margin: 0 }}>
            प्रत्येक सह़ी उत्तर का मूल्य 100 अंक िक है। अन्य खिलालडयों क़ी िुलना
            में आपके उत्तर लजिने दुलगभ होंर्े, आपका स्कोर उिना ह़ी अलिक होर्ा।
          </p>
          <p style={{ margin: 0 }}>
            यलद आप लकस़ी ऐसे खिलाड़ी का अनुमान लर्ािे हैं लजसका अनुमान के वल 10%
            अन्य खिलालडयों ने लर्ाया है, िो आप उस वर्ग के ललए 100 - 10% = 90
            स्कोर करेंर्े।
          </p>
          <p style={{ margin: 0 }}>
            यलद आप एक सामान्य उत्तर का अनुमान लर्ािे हैं लजसका अनुमान 60% अन्य
            खिलालडयों ने भ़ी लर्ाया है, िो आप उस वर्ग के ललए 100 - 60% = 40 अंक
            प्राप्त करेंर्े।
          </p>
          <p style={{ margin: 0 }}>
            र्ेम के अंि में आपका कु ल स्कोर सभ़ी 9 वर्ों का योर् है।
          </p>
          <p style={{ margin: 0 }}>लकस़ी भ़ी ररि वर्ग का स्कोर 0 है। </p>
          <p></p>
          <p>
            आप 900 के लजिना कऱीब पहुंच सकें , उिना बेहिर होर्ा! आपको कामयाब़ी
            लमले।
          </p>
        </InstructionBox>
      </InstructionPopup>
    </BaseModal>
  );

  useEffect(() => {
    const localeStorageToken = localStorage.getItem(DICTIONARY.TOKEN);
    if (!localeStorageToken) {
      setOpenInstruction(true);
    }
  }, []);

  return (
    <DashboardNavbarRoot>
      <Logo />
      <DashboardMenuRoot>
        <DashboardIconRoot>
          <BarChartIcon sx={{ color: theme.white }} onClick={handleOpen} />
        </DashboardIconRoot>

        <DashboardIconRoot>
          <InfoOutlinedIcon
            sx={{ color: theme.blue }}
            onClick={handleOpenInstruction}
          />
        </DashboardIconRoot>
      </DashboardMenuRoot>

      {renderPopup}
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
